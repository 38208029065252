<!-- 作品上传-->

<template>
    <div class="views-upload-works">
        <div class="upload-works-content">
            <div class="upload-works-form">
                <h4 class="title">视频上传</h4>
                <van-form @submit="onSubmit" ref="form">
                    <van-field
                            v-model="form.Name"
                            label-class="item-label"
                            name="Name"
                            label="作品名称"
                            placeholder="作品名称"
                            maxlength="40"
                            required
                            :rules="[{ required: true, message: '请填写作品名称' }]"
                    />
                    <van-field name="上传封面" label="上传封面" label-class="item-label" class="item-upload">
                        <template #input>
                            <!--                            <span @click="uploadImage">123</span>-->
                            <!--                            <img v-if="imgUrl" :src="imgUrl"/>-->
                            <div class="van-uploader" @click="uploadImage">
                                <div class="van-uploader__wrapper">
                                    <div class="van-uploader__upload">
                                        <i class="van-icon van-icon-photograph van-uploader__upload-icon"></i>
                                        <!--                                        <input type="file" accept="image/*" class="van-uploader__input">-->
                                        <img v-if="imgUrl" :src="imgUrl" class="preview-img"/>
                                    </div>
                                </div>
                            </div>
                        </template>


                        <!--                        <template #input>-->
                        <!--                            <van-uploader-->
                        <!--                                    max-count="1"-->
                        <!--                                    v-model="fileList"-->
                        <!--                                    :afterRead="afterRead"-->
                        <!--                                    :before-read="uploadImage"-->
                        <!--                            />-->
                        <!--                        </template>-->
                    </van-field>
                    <p class="warning-text">温馨提示：参赛者生活照，JPG格式，大小不超过5M，横版</p>
                    <van-field name="上传作品" label="上传作品" label-class="item-label" class="item-upload" required>
                        <template #input>
                            <van-uploader
                                    max-count="1"
                                    v-model="videoList"
                                    :afterRead="afterReadVideo"
                                    accept="video/*"
                                    :delete="delVideo"
                            />
                            <p>{{statusText}}</p>
                        </template>
                    </van-field>
                    <p class="warning-text">温馨提示：视频文件格式建议为MP4，清晰度1080P，画幅16:9（横屏），单个文件大小不超过600M，画面清晰稳定。</p>
                </van-form>
            </div>
            <div class="submit-box">
                <div class="submit-button" @click="subForm">确认提交</div>
            </div>
        </div>
        <Back></Back>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {Uploader, Form, Toast} from 'vant'
    import core from '../core/index'
    import getStrParams from "../utils/getStrParams";
    import {videoCreate, uploadWXImage, createWorks, hasUpFileInfo, myWorksList} from '../server'
    import initWXAPI from "../utils/initWXAPI";
    import wx from "weixin-js-sdk";
    import Back from "../components/Back";
    import WXShare from "../utils/WXShare";

    Vue.use(Form)
    Vue.use(Uploader)

    export default {
        name: "UploadWorks",
        components: {Back},
        data() {
            return {
                videoList: [],
                sessionId: '',
                imgUrl: '',
                videoId: '',
                form: {
                    Name: ''
                },
                fileList: [],


                timeout: '',
                partSize: '',
                parallel: '',
                retryCount: '',
                retryDuration: '',
                region: 'cn-shanghai',
                userId: '1421929043250523',
                file: null,
                stsProgress: 0,
                uploadDisabled: true,
                resumeDisabled: true,
                pauseDisabled: true,
                statusText: '',
                videoUploadStatus: '0',
                uploader: null
            }
        },
        methods: {
            async onSubmit(values) {
                console.log(values.Name)
                if (this.videoId) {
                    const data = await createWorks({
                        SessionId: this.sessionId,
                        Name: values.Name,
                        PicUrl: this.imgUrl,
                        VideoId: this.videoId
                    })
                    console.log(data)
                    if (data.Data.Id) {
                        Toast('提交成功')
                        this.$router.replace({path: 'userCenter'})
                    } else if (data.Data.ErrorMsg) {
                        Toast(data.Data.ErrorMsg)
                    } else {
                        Toast('提交失败，请稍后重试')
                    }
                } else {
                    Toast('请先上传作品')
                }
            },
            delVideo() {
                this.videoId = ''
                this.statusText = ''
            },
            uploadImage() {
                const self = this
                wx.chooseImage({
                    count: 1, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (res) {
                        const localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                        wx.uploadImage({
                            localId: localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (res) {
                                self.uploadLocalImage(res.serverId)
                            }
                        });
                    },
                    fail: function (res) {
                        Toast(res.errMsg)
                    }
                });
            },
            async uploadLocalImage(serverId) {
                const data = await uploadWXImage({serverId: serverId})
                if (data.Data.PicUrl) {
                    this.imgUrl = data.Data.PicUrl
                } else if (data.Data.ErrorMsg) {
                    Toast(data.Data.ErrorMsg)
                } else {
                    Toast('上传失败，请稍后重试')
                }
            },
            subForm() {
                this.$refs.form.submit()
            },

            afterReadVideo(value) {
                var userData = '{"Vod":{}}'
                if (this.uploader) {
                    this.uploader.stopUpload()
                    this.authProgress = 0
                    this.statusText = ""
                }
                this.uploader = this.createUploader(value)
                console.log(userData)
                this.uploader.addFile(value.file, null, null, null, userData)
                this.uploadDisabled = false
                this.pauseDisabled = true
                this.resumeDisabled = true
            },
            createUploader(value) {
                let self = this
                let uploader = new AliyunUpload.Vod({
                    timeout: self.timeout || 60000,
                    partSize: self.partSize || 1048576,
                    parallel: self.parallel || 5,
                    retryCount: self.retryCount || 3,
                    retryDuration: self.retryDuration || 2,
                    region: self.region,
                    userId: self.userId,
                    // 添加文件成功
                    addFileSuccess: function (uploadInfo) {
                        self.uploadDisabled = false
                        self.resumeDisabled = false
                        self.statusText = '添加文件成功, 等待上传...'
                        console.log("addFileSuccess: " + uploadInfo.file.name)
                        this.onUploadstarted(uploadInfo)
                    },
                    // 开始上传
                    onUploadstarted: async function (uploadInfo) {
                        // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
                        // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
                        // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口


                        if (!uploadInfo.videoId) {
                            const data = await videoCreate({FileName: value.file.name, FileSize: value.file.size})
                            uploader.setUploadAuthAndAddress(uploadInfo, data.Data.UploadAuth, data.Data.UploadAddress, data.Data.VideoId)

                            self.statusText = '文件开始上传...'
                            console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                        } else {
                            const data = await videoCreate({FileName: value.file.name, FileSize: value.file.size})

                            uploader.setUploadAuthAndAddress(uploadInfo, data.Data.UploadAuth, data.Data.UploadAddress, data.Data.VideoId)
                        }
                    },
                    // 文件上传成功
                    onUploadSucceed: function (uploadInfo) {
                        console.log("onUploadSucceed: " + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                        self.statusText = '文件上传成功!'
                        self.videoUploadStatus = '1'
                        self.videoId = uploadInfo.videoId
                    },
                    // 文件上传失败
                    onUploadFailed: function (uploadInfo, code, message) {
                        console.log("onUploadFailed: file:" + uploadInfo.file.name + ",code:" + code + ", message:" + message)
                        self.statusText = '文件上传失败!'
                    },
                    // 取消文件上传
                    onUploadCanceled: function (uploadInfo, code, message) {
                        console.log("Canceled file: " + uploadInfo.file.name + ", code: " + code + ", message:" + message)
                        self.statusText = '文件已暂停上传'
                    },
                    // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
                    onUploadProgress: function (uploadInfo, totalSize, progress) {
                        console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize + ", percent:" + Math.ceil(progress * 100) + "%")
                        let progressPercent = Math.ceil(progress * 100)
                        self.authProgress = progressPercent
                        self.statusText = '文件上传中...'
                    },
                    // 上传凭证超时
                    onUploadTokenExpired: async function (uploadInfo) {
                        // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
                        // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth


                        const data = await videoCreate({FileName: value.file.name, FileSize: value.file.size})

                        uploader.resumeUploadWithAuth(data.Data.uploadAuth)
                        console.log('upload expired and resume upload with uploadauth ' + data.Data.uploadAuth)

                        self.statusText = '文件超时...'
                    },
                    // 全部文件上传结束
                    onUploadEnd: function (uploadInfo) {
                        console.log("onUploadEnd: uploaded all the files")
                        self.statusText = '文件上传完毕'
                    }
                })
                return uploader
            },
            async getWorks() {
                const data = await myWorksList({SessionId: core.cookie.getStorage('sessionId')});
                if (data.ErrorMsg) {
                    Toast(data.ErrorMsg);
                    return;
                }
                const myWork = data.Data.list[0];
                this.imgUrl = myWork.CoverURL;
                this.form.Name = myWork.Name;
                this.videoId = myWork.VideoId
                this.videoList.push(myWork.PlayURL)
                Toast('您已经上传过作品，如果重复上传将会覆盖之前的作品，视频一经审核将无法修改')
            }
        },
        created() {
            const params = getStrParams.getStrParams(window.location.href.split('?')[1])
            this.sessionId = core.cookie.getStorage('sessionId')
            this.type = '1';
            this.hasWorks = params.hasWorks
            if (this.hasWorks === '1') {
                this.getWorks()
            }
          //  initWXAPI.init(this.$store.state.url)
            WXShare.localShare()  // 微信分享
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/index";

    .views-upload-works {
        background: url("../static/image/ny_bg.png") no-repeat top left #be0b15;
        background-size: contain;
        padding-top: toRem(400);
        min-height: 100vh;
        box-sizing: border-box;
        .upload-works-content {
            position: relative;

            .upload-works-form {
                background: #fff;
                margin: toRem(12);
                border-radius: toRem(12);
                padding: toRem(30) toRem(40);

                .title {
                    color: $basicFontColor;
                    text-align: center;
                    font-size: toRem(32);
                    background: url("../static/image/icon/bg_iconbt.png") no-repeat center center;
                    background-size: auto toRem(65);
                    font-weight: normal;
                    margin-bottom: toRem(20);
                }

                .warning-text {
                    font-size: toRem(22);
                    line-height: 1.5;
                    color: #B2B2B2;
                    padding-bottom: toRem(20);
                    border-bottom: toRem(1) solid #f8f8f8;
                }

                .preview-img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }

            .submit-box {
                padding: 0 toRem(40) toRem(40);

                .submit-button {
                    height: toRem(110);
                    line-height: toRem(110);
                    text-align: center;
                    font-size: toRem(34);
                    background: url("../static/image/btn_ju.png") no-repeat top left;
                    background-size: contain;
                }
            }
        }

        /*.item-label{*/
        /*    border-left: toRem(5) solid red;*/
        /*    padding-left: toRem(10);*/
        /*}*/
    }
</style>
<style>
    .views-upload-works .upload-works-content .item-upload::after {
        border: none;
    }

    .views-upload-works .upload-works-content .item-label {
        padding-left: 0.3rem;
    }

    /*.views-upload-works .upload-works-content .item-label :before {*/
    /*    margin-right: 0.3rem;*/
    /*    content: '。';*/
    /*    color: transparent;*/
    /*    display: inline-block;*/
    /*    width: 0.4rem;*/
    /*    background-image: linear-gradient(#ffdc34, #ffaf00);*/
    /*    border-radius: 0.2rem;*/
    /*}*/
</style>